@import '../../../styles/variables';

.ant-menu {
    padding-top: 16px;
}

.menu-logo {
    border-right: 1px solid #0505050f;
    background: #292e39
}

.ant-menu-item {
    background-color: transparent !important;
}

.custom-menu-color {
    .ant-menu {
        background: #292e39
    }

    .ant-menu-item {
        color: #fff;

        &:hover {
            color: $secondary-color !important;
            background: #1d2129 !important;
        }
    }

    .ant-menu-submenu-title {
        color: #fff;

        &:hover {
            color: $secondary-color !important;
            background: #1d2129 !important;
        }
    }

    .ant-menu-submenu {
        border-radius: 0px;
    }

    .ant-menu-submenu-selected {
        color: $secondary-color !important;
        background: #1d2129 !important;
    }

    .ant-menu-item-selected {
        color: $secondary-color !important;
    }

    :where(.css-dev-only-do-not-override-vawxn).ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline,
    :where(.css-dev-only-do-not-override-vawxn).ant-menu-light>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
        background: #1c2028;
    }
}