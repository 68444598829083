.select-container {
    .ant-typography {
        font-size: 14px !important;
    }

    .ant-select {
        width: 100% !important;
    }

    .ant-select-selector {
        border-radius: 4px !important;
    }
}