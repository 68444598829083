.color-picker-container {
    display: flex;
    flex-direction: column;

    .ant-typography {
        font-size: 14px !important;
    }

    .ant-color-picker-trigger {
        border-radius: 4px !important;
        justify-content: flex-start !important;
    }
}