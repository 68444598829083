@import '../styles/variables';


.box {
    padding: 20px;
    height: calc(100vh - 70px);
    background: #eff0f5;
    overflow: auto;

    @media(max-width: 768px) {
        padding: 16px;
    }
}

.box::-webkit-scrollbar {
    width: 3px;
}

.box::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.box::-webkit-scrollbar-thumb {
    background: #ababab;
    border-radius: 10px;
}

.box::-webkit-scrollbar-thumb:hover {
    background: #ababab;
}

.menu-custom {
    max-width: 240px;
}

.header {
    background: #eff0f5;
    color: #444;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;


    .header-user-icon {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h2 {
        font-size: 20px;
        font-weight: 500;
    }
}

.ant-menu-item {
    background-color: #FAFAFA; // Cor de fundo do item selecionado
}

.custom-menu {
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        transition: width 0.2s ease, height 0.2s ease;
    }

    /* Exibir a barra de rolagem ao passar o mouse */
    .scrollable-content:hover {
        width: 3px;
        height: 3px;
    }

    /* Estilo do trilho da barra de rolagem */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
    }

    /* Estilo do polegar da barra de rolagem */
    ::-webkit-scrollbar-thumb {
        background: #e2e2e2;
        border-radius: 10px;
    }

    /* Estilo do polegar ao passar o mouse */
    .scrollable-content:hover {
        background: f1f1f1;
    }

    .ant-menu-root {
        height: calc(100vh - 52px);
        overflow: auto;
    }
}


.header-mobile {
    background: #eff0f5;
    color: #444;
    padding: 8px 24px;
    height: 65px;
}