.input-container {
    width: 100%;

    .ant-typography {
        font-size: 14px !important;
    }

    .ant-picker {
        border-radius: 4px !important;
    }

    .ant-input {
        border-radius: 4px !important;
    }
}